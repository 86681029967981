// function loadPosts(cat_name, posts, tag, page, more = false) {
//     var data_posts = posts != null ? posts : null;
//     var data_cat_name = cat_name != null ? cat_name : null;
//     var data_tag = tag != null ? tag : null;
//     var data_page = page != null ? page : null;
//     jQuery.ajax({
//         url: acoe_vars.ajaxurl,
//         type: "post",
//         data: {
//             action: "kasa_ajax_getPosts",
//             posts: data_posts,
//             cat_name: data_cat_name,
//             tag: data_tag,
//             page: data_page,
//         },
//         beforeSend: function () {
//             console.log("loading");
//             if (more == false) {
//                 jQuery("#posts-container").empty();
//             }
//             jQuery(".posts__loading").show();
//         },
//         success: function (data) {
//             console.log("success");
//             if (more == false) {
//                 jQuery("#posts-container").html(data);
//             } else {
//                 jQuery(".posts__more").remove();
//                 jQuery("#posts-container").append(data);
//             }
//             jQuery(".posts__loading").hide();
//         },
//         error: function () {
//             jQuery(".posts__loading").html(
//                 "There was an error loading the posts"
//             );
//         },
//     });
// }

(function ($) {
  //loadPosts();

  $(".logos-carousel__slider").slick({
    arrows: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4
  });

  // $(".fancybox").fancybox({
  //     padding: 0,
  // });

  const open_menu = document.getElementById("open-menu");
  const close_menu = document.getElementById("close-menu");
  if (open_menu) {
    open_menu.addEventListener("click", () => {
      document.querySelector(".header__nav").classList.add("open");
    });
    close_menu.addEventListener("click", () => {
      document.querySelector(".header__nav").classList.remove("open");
    });
  }
  function scrollDown() {
    var win_width = window.innerWidth;
    var top = window.scrollY;

    //Header animation
    const headerEl = document.getElementById("header");
    if (win_width > 600) {
      if (top > 80) {
        headerEl.classList.add("scroll");
        //headerLogo.setAttribute('src',logoData);
      } else {
        headerEl.classList.remove("scroll");
        //headerLogo.setAttribute('src',logoSrc);
      }
    }

    //Scroll animations
    var animatedEl = document.getElementsByClassName("animate-fade");
    var h = window.innerHeight;
    var i;
    for (i = 0; i < animatedEl.length; i++) {
      var viewportOffset = animatedEl[i].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h * 0.8) {
        animatedEl[i].classList.add("fade-in");
      }
    }

    // Lazy Load
    var lazy_image = document.querySelectorAll("[data-src]");
    if (lazy_image) {
      //console.log(lazy_image);
      for (var l = 0; l < lazy_image.length; l++) {
        var data_src = lazy_image[l].dataset.src;
        var viewportOffset = lazy_image[l].getBoundingClientRect();
        var topPos = viewportOffset.top;
        if (topPos <= h * 1.3) {
          lazy_image[l].setAttribute("src", data_src);
          lazy_image[l].removeAttribute("data-src");
          lazy_image[l].classList.add("loaded");
        }
      }
    }

    // Lazy Background
    var lazy_bg = document.querySelectorAll("[data-bg]");
    if (lazy_bg) {
      //console.log(lazy_bg);
      for (var l = 0; l < lazy_bg.length; l++) {
        var data_src = lazy_bg[l].dataset.bg;
        var viewportOffset = lazy_bg[l].getBoundingClientRect();
        var topPos = viewportOffset.top;
        if (topPos <= h * 1.3) {
          lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
          lazy_bg[l].removeAttribute("data-bg");
          lazy_bg[l].classList.add("loaded");
        }
      }
    }
  }
  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);